import * as _ from 'lodash';

import {
  leftMarkerENCursor,
  leftMarkerFRCursor,
  rightMarkerENCursor,
  rightMarkerFRCursor,
} from 'app/CornerstoneTools/Cursors';
import { addInputBox } from 'app/components/FloatingInputCallback';
import { IntlShape } from 'react-intl';
import { ToolConfiguration } from 'app/adapters/ImageRenderer/ConfigurableToolsOptions';

// @ts-ignore
import leftMarkerImageEN from '../../../static/svg_icons/left_marker_cornerstone_en.svg?url';
// @ts-ignore
import leftMarkerImageFR from '../../../static/svg_icons/left_marker_cornerstone_fr.svg?url';
// @ts-ignore
import rightMarkerImageEN from '../../../static/svg_icons/right_marker_cornerstone_en.svg?url';
// @ts-ignore
import rightMarkerImageFR from '../../../static/svg_icons/right_marker_cornerstone_fr.svg?url';
import { RealSizeMeasurementCalibrationMeasurement } from 'app/CornerstoneTools/RealSizeMeasurementCalibrationTool';

const getTextCallback = (doneChangingTextCallback: (text: string) => void, eventData: any) => {
  addInputBox(doneChangingTextCallback, eventData);
};

function changeTextCallbackForTextMarker(
  data: any,
  eventData: any,
  doneChangingTextCallback: (data: any, text: string) => void
) {
  addInputBox((text: string) => doneChangingTextCallback(data, text), eventData, data.text);
}

function changeTextCallbackForArrowAnnotate(
  data: any,
  eventData: any,
  doneChangingTextCallback: (text: string) => void
) {
  addInputBox((text: string) => doneChangingTextCallback(text), eventData, data.text);
}

const getLengthCallback = (
  title: string,
  doneChangingTextCallback: (length: number) => void,
  eventData: any
) => {
  addInputBox(doneChangingTextCallback, eventData, {
    title,
    inputProps: {
      type: 'number',
      step: 0.5,
    },
  });
};
const updateLengthCallback = (
  title: string,
  doneChangingTextCallback: (length: number) => void,
  data: RealSizeMeasurementCalibrationMeasurement,
  eventData: any
) => {
  addInputBox(doneChangingTextCallback, eventData, {
    title,
    defaultValue: data.length,
    inputProps: {
      type: 'number',
      step: 0.5,
    },
  });
};

export const INTL_MARKERS = {
  en: {
    leftMarker: {
      cursor: leftMarkerENCursor,
      source: leftMarkerImageEN,
      img: undefined as HTMLImageElement,
    },
    rightMarker: {
      cursor: rightMarkerENCursor,
      source: rightMarkerImageEN,
      img: undefined as HTMLImageElement,
    },
  },
  fr: {
    leftMarker: {
      cursor: leftMarkerFRCursor,
      source: leftMarkerImageFR,
      img: undefined as HTMLImageElement,
    },
    rightMarker: {
      cursor: rightMarkerFRCursor,
      source: rightMarkerImageFR,
      img: undefined as HTMLImageElement,
    },
  },
};

// Doing this preloading avoid having to load it in each cornerstone display.
// This also fix issue with cornerstoneTools inject that run in a tight loop.
function loadMarkerImages() {
  _.forEach(INTL_MARKERS, (markers) => {
    _.forEach(markers, (marker) => {
      const img = new Image();
      img.onload = () => {
        marker.img = img;
      };
      img.src = marker.source;
    });
  });
}

loadMarkerImages();

const INITIAL_COMMON_TOOLS_LIST = {
  ZoomMouseWheel: {
    state: 'active',
    options: {
      name: 'ZoomMouseWheel',
      // This button mask does not exist, it is here to denote
      // that multiple tools with zoomwheel share the same control method.
      mouseButtonMask: 100,
      configuration: {
        cropToolName: 'Crop',
      },
    },
  } as ToolConfiguration,
  FineGrainZoomMouseWheel: {
    state: 'disabled',
    options: {
      name: 'FineGrainZoomMouseWheel',
      mouseButtonMask: 100,
      configuration: {
        ticksMultiplier: 1 / 30,
      },
    },
  } as ToolConfiguration,
  ZoomTouchPinch: {
    state: 'active',
    options: {
      name: 'ZoomTouchPinch',
      mouseButtonMask: 0,
    },
  } as ToolConfiguration,
  Pan: {
    state: 'active',
    options: {
      name: 'Pan',
      mouseButtonMask: 1,
    },
  } as ToolConfiguration,
  PanMultiTouch: {
    state: 'active',
    options: {
      name: 'PanMultiTouch',
      mouseButtonMask: 102,
    },
  } as ToolConfiguration,
  PanWheel: {
    state: 'active',
    options: {
      name: 'PanWheel',
      mouseButtonMask: 4,
    },
  } as ToolConfiguration,
  Eraser: {
    state: 'disabled',
    options: {
      name: 'Eraser',
      mouseButtonMask: 1,
    },
  } as ToolConfiguration,
  // Specific tool for enabling only Mouse right click without interfering on touch
  WWWCRightClick: {
    state: 'active',
    options: {
      name: 'WWWCRightClick',
      mouseButtonMask: 2,
      supportedInteractionTypes: ['Mouse'],
    },
  } as ToolConfiguration,
  WWWC: {
    state: 'disabled',
    options: {
      name: 'WWWC',
      mouseButtonMask: 1,
      isTouchActive: true,
    },
  } as ToolConfiguration,
};

const INITIAL_IMAGE_TOOLS_LIST = {
  ArrowAnnotate: {
    state: 'passive',
    options: {
      name: 'ArrowAnnotate',
      mouseButtonMask: 1,
      deleteIfHandleOutsideImage: false,
      preventHandleOutsideImage: false,
      configuration: {
        allowEmptyLabel: true,
        drawHandlesOnHover: true,
        getTextCallback,
        changeTextCallback: changeTextCallbackForArrowAnnotate,
      },
    },
  } as ToolConfiguration,
  TextMarker: {
    state: 'passive',
    options: {
      name: 'TextMarker',
      mouseButtonMask: 1,
      configuration: {
        getTextCallback,
        changeTextCallback: changeTextCallbackForTextMarker,
      },
    },
  } as ToolConfiguration,
  Length: {
    state: 'passive',
    options: {
      name: 'Length',
      mouseButtonMask: 1,
    },
  } as ToolConfiguration,
  CircleRadius: {
    state: 'passive',
    options: {
      name: 'CircleRadius',
      mouseButtonMask: 1,
    },
  } as ToolConfiguration,
  Angle: {
    state: 'passive',
    options: {
      name: 'Angle',
      mouseButtonMask: 1,
    },
  } as ToolConfiguration,
  Magnify: {
    state: 'disabled',
    options: {
      name: 'Magnify',
      mouseButtonMask: 1,
    },
  } as ToolConfiguration,
  VHS: {
    state: 'disabled',
    options: {
      name: 'VHS',
      mouseButtonMask: 0,
    },
  } as ToolConfiguration,
  // Putting this tool last will make it mask all the other tools.
  Crop: {
    state: 'passive',
    options: {
      name: 'Crop',
      mouseButtonMask: 1,
    },
  } as ToolConfiguration,
  ScaleOverlay: {
    state: 'active',
    options: {
      name: 'ScaleOverlay',
    },
  } as ToolConfiguration,
};

const THUMBNAIL_TOOLS_LIST = {
  Crop: {
    state: 'passive',
    options: {
      name: 'Crop',
      mouseButtonMask: 1,
    },
  } as ToolConfiguration,
};

// Freeze initialization data to avoid unwanted mutation.
Object.freeze(INITIAL_COMMON_TOOLS_LIST);
Object.freeze(INITIAL_IMAGE_TOOLS_LIST);
Object.freeze(THUMBNAIL_TOOLS_LIST);

const createCommonToolsList = (getIntl: () => IntlShape) => ({
  ...INITIAL_COMMON_TOOLS_LIST,
});

const createImageToolsList = (getIntl: () => IntlShape) => {
  const intlMarkers = getIntl().locale === 'fr' ? INTL_MARKERS.fr : INTL_MARKERS.en;
  return _.merge({}, _.cloneDeep(INITIAL_IMAGE_TOOLS_LIST), {
    // Tools with instructions must be after Crop, else the cropped area will override them.
    DistractionIndex: {
      state: 'passive',
      options: {
        name: 'DistractionIndex',
        mouseButtonMask: 1,
        configuration: { getIntl },
      },
    } as ToolConfiguration,
    NorbergOlsson: {
      state: 'disabled',
      options: {
        name: 'NorbergOlsson',
        mouseButtonMask: 1,
        configuration: { getIntl },
      },
    } as ToolConfiguration,
    VHS: {
      state: 'disabled',
      options: {
        name: 'VHS',
        mouseButtonMask: 1,
        configuration: { getIntl },
      },
    } as ToolConfiguration,
    LeftMarker: {
      state: 'passive',
      options: {
        name: 'LeftMarker',
        mouseButtonMask: 1,
        configuration: {
          markerSource: intlMarkers.leftMarker.source,
          markerImage: intlMarkers.leftMarker.img,
        },
        svgCursor: intlMarkers.leftMarker.cursor,
      },
    } as ToolConfiguration,
    RightMarker: {
      state: 'passive',
      options: {
        name: 'RightMarker',
        mouseButtonMask: 1,
        configuration: {
          markerSource: intlMarkers.rightMarker.source,
          markerImage: intlMarkers.rightMarker.img,
        },
        svgCursor: intlMarkers.rightMarker.cursor,
      },
    } as ToolConfiguration,

    RealSizeMeasurementCalibration: {
      state: 'disabled',
      options: {
        name: 'RealSizeMeasurementCalibration',
        mouseButtonMask: 1,
        configuration: {
          getLengthCallback: (doneChangingTextCallback: (length: number) => void, eventData: any) =>
            getLengthCallback(
              getIntl().formatMessage({ id: 'tools.real_size.measurement_title' }),
              doneChangingTextCallback,
              eventData
            ),
          updateLengthCallback: (
            doneChangingTextCallback: (length: number) => void,
            data: RealSizeMeasurementCalibrationMeasurement,
            eventData: any
          ) =>
            updateLengthCallback(
              getIntl().formatMessage({ id: 'tools.real_size.measurement_title' }),
              doneChangingTextCallback,
              data,
              eventData
            ),
        },
      },
    } as ToolConfiguration,
    TPLO: {
      state: 'passive',
      options: {
        name: 'TPLO',
        mouseButtonMask: 1,
        configuration: { getIntl },
      },
    } as ToolConfiguration,
    DicomDataPrinter: {
      state: 'passive',
      options: {
        name: 'DicomDataPrinter',
        mouseButtonMask: 1,
        configuration: { getIntl },
      },
    } as ToolConfiguration,
  });
};

const createThumbnailToolsList = () => _.cloneDeep(THUMBNAIL_TOOLS_LIST);

export { createCommonToolsList, createImageToolsList, createThumbnailToolsList };
