import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeSelectLocale } from 'app/containers/LanguageProvider/selectors';

const IntlCrawlableLink = ({ children, className, style, url, onClick, target }) => {
  const locale = useSelector(makeSelectLocale());
  return (
    <Link
      className={className}
      style={style}
      to={`/${locale}${url}`}
      onClick={onClick}
      target={target}
    >
      {children}
    </Link>
  );
};

IntlCrawlableLink.defaultProps = {
  className: null,
  onClick: undefined,
  style: null,
  target: undefined,
};

IntlCrawlableLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  target: PropTypes.string,
};

export default IntlCrawlableLink;
